<template>
  <div class="consentBanner">
    <p>{{ title }}</p>
    <ButtonCTA
      :content="{
        icon: 'ion:build',
        text: button,
      }"
      variant="primary"
      @click="handleClick()"
    />
  </div>
</template>

<script setup lang="ts">
defineProps<{
  title: string;
  button: string;
}>();

const emit = defineEmits(['activate']);

const handleClick = (): void => {
  emit('activate');
};
</script>

<style lang="scss" scoped>
.consentBanner {
  --local-text--color-dark: var(--color-text-bodytext_default);
  --local-background-color: var(--color-surfaces-light);
  --local-item--padding: #{easy-rem(10)};
}

.consentBanner {
  background-color: var(--local-background-color);

  height: 100%;
  width: 100%;

  @include easy-flex-block-vertical();
  align-items: center;
  justify-content: center;

  p {
    @include font-title-h5();
    color: var(--local-text--color-dark);
    text-align: center;
    width: calc(100% - var(--local-item--padding) * 2);

    display: block;
  }
}
</style>
